import React from "react";

import RedTigerHeaderLogo from "./header-logo";
import RedTigerHeaderSelection from "./header-selection";

function RedTigerHeader() {
    return(
        <header className="header-container">
            <RedTigerHeaderLogo />
            <RedTigerHeaderSelection />
        </header>
    )
}

export default RedTigerHeader
import React from "react";

function About() {
    return (
        <div>
            <h1>About Red-Tiger</h1>
            <p>
                Red-Tiger is a leading technology company specializing in cutting-edge software development and consulting. We offer a wide range of services, including API development, mobile app development, and software development support.
            </p>
        </div>
    );
}

export default About;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import RedTigerHeader from './components/header';
import Home from './pages/Home';
import ValheimCraftingWebApp from './pages/ValheimCraftingWebApp';
import GymTrakWebApp from './pages/GymTrakWebApp';
import About from './pages/About';

function App() {
  return (
    <Router>
      <div className="App">
        < RedTigerHeader />

        <Routes>
          <Route path='/' element={< Home />} />
          <Route path='/ValheimCraftingWebApp' element={< ValheimCraftingWebApp />} />
          <Route path='/GymTrakWebApp' element={< GymTrakWebApp />} />

          <Route path='/About' element={< About />} />
        </Routes>
        
      </div>
    </Router>

  );
}

export default App;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function RedTigerHeaderSelection() {
    const [activeMenu, setActiveMenu] = useState(null);

    // Function to handle mouse enter and leave
    const handleMouseEnter = (menu) => {
        setActiveMenu(menu);
    };

    const handleMouseLeave = () => {
        setActiveMenu(null);
    };

    return (
        <nav className="header-selection-container">
            <div 
                className="header-selection-item" 
                id="header-selection-item-webapps" 
                onMouseEnter={() => {handleMouseEnter('webapps')}}
                onMouseLeave={handleMouseLeave}
            >
                WebApps
                <div className={`header-selection-item-dropdown ${activeMenu === 'webapps' ? 'visible' : ''}`}>
                    <Link to="/ValheimCraftingWebApp" className='header-selection-item-dropdown-item'>Valheim Crafting</Link>
                    <Link to="/GymTrakWebApp" className='header-selection-item-dropdown-item'>GymTrak</Link>
                </div>
            </div>

            <div 
                className="header-selection-item" 
                id="header-selection-item-apis"
                onMouseEnter={() => {handleMouseEnter('apis')}}
                onMouseLeave={handleMouseLeave}
            >
                APIs
                <div className={`header-selection-item-dropdown ${activeMenu === 'apis' ? 'visible' : ''}`}>
                    <Link to="/ValheimCraftingAPI" className='header-selection-item-dropdown-item'>Valheim Crafting API</Link>
                    <Link to="/GymTrakAPI" className='header-selection-item-dropdown-item'>GymTrak</Link>
                </div>
            </div>

            <div 
                className="header-selection-item" 
                id="header-selection-item-apps"
                onMouseEnter={() => {handleMouseEnter('apps')}}
                onMouseLeave={handleMouseLeave}
            >
                Apps
                <div className={`header-selection-item-dropdown ${activeMenu === 'apps' ? 'visible' : ''}`}>
                    <Link to="/ValheimCraftingApp" className='header-selection-item-dropdown-item'>Valheim Crafting</Link>
                    <Link to="/GymTrakApp" className='header-selection-item-dropdown-item'>GymTrak</Link>
                </div>
            </div>

            <Link to="/About" className="header-selection-item" id="header-selection-item-about">
                About
            </Link>
        </nav>
    )
}

export default RedTigerHeaderSelection
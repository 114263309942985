import React from "react";
import { Link } from "react-router-dom";


function RedTigerHeaderLogo() {
    return(
        <Link to="/" className="header-logo-container">
            <img className="header-logo" src={`${process.env.PUBLIC_URL}/Red-Tiger_Logo_v4.0_no_background.png`} alt="Logo" />
            <h1 className="header-title">Red-Tiger</h1>
        </Link>
    )
}

export default RedTigerHeaderLogo


function GymTrakWebApp() {
    return (
        <div>
            <h1>GymTrak Web App</h1>
            <p>This is a simple web application for tracking gym progress.</p>
        </div>
    )
}

export default GymTrakWebApp;
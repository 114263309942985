import React from "react";

function ValheimCraftingWebApp() {
    return (
        <div>
            <h1>Valheim Crafting Web App</h1>
            <p>This is a simple web application for crafting items in the game Valheim.</p>
        </div>
    );
}

export default ValheimCraftingWebApp;